import ReactGA4 from "react-ga4";

const InitGoogleAnalytics = () => {
  const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID;

  ReactGA4.initialize(TRACKING_ID);

  // Keep console log for future debugging purposes since tracking can only be viewed in GA when on prod.
  // console.log("GA INITIALIZED");
  // console.log("GoogleAnalytics.js - TRACKING_ID:", TRACKING_ID);
};

const TrackGoogleAnalyticsPageView = (path, title) => {
  // Keep console log for future debugging purposes since tracking can only be viewed in GA when on prod.
  // console.log("path:", path, "\ntitle:", title);

  ReactGA4.send({
    hitType: "pageview",
    page: path,
    title: title,
  });
};

export default InitGoogleAnalytics;
export { InitGoogleAnalytics, TrackGoogleAnalyticsPageView };
