import React from "react";
import { Button, Spinner } from "reactstrap";

function LAZButton({ children, color = "primary", loading = false, ...rest }) {
  return (
    <Button className="focus-event" color={color} {...rest}>
      {loading ? (
        <Spinner animation="border" size="sm" variant="light" />
      ) : (
        children
      )}
    </Button>
  );
}

export default LAZButton;
