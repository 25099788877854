// https://www.codemzy.com/blog/fix-chunkloaderror-react

import BugsnagManager from "./BugsnagManager";

const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport()
      .then((component) => {
        console.log(`${name} loaded!`);
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        console.warn(error);
        if (!hasRefreshed) {
          BugsnagManager.notify(error, {
            context: "ChunkLoadError caught - page refreshed",
          });
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default lazyRetry;
