export function getFirstTwoPaths(urlPath) {
  const segments = urlPath.split("/").filter((segment) => segment !== "");
  const firstThreeSegments = segments.slice(0, 2);
  const result = "/" + firstThreeSegments.join("/");
  return result;
}

export function getAirportClientFromPath() {
  return window.location.pathname.split("/").filter((x) => x)[0] || "";
}

export function constructDateWithTimezoneIdentifier(
  date = new Date(),
  timeZoneIdentifier
) {
  return new Date(
    new Date(date).toLocaleString("en-US", {
      timeZone: timeZoneIdentifier,
    })
  );
}
