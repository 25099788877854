import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from "../types/location.types";
import { fetchWindowLocationInformation } from "../../components/Utils/LocationUtil";

export const locationChangeAction = (location, action) => {
  if (location) {
    const { isParkingStartingNow, searchTerm, query, enteredViaWidget } =
      fetchWindowLocationInformation(location);
    location.startString = query.start
      ? new Date(query.start).toLocaleString()
      : "";
    location.endString = query.end ? new Date(query.end).toLocaleString() : "";
    location.isParkingStartingNow = isParkingStartingNow;
    location.searchTerm = searchTerm;
    location.query = query;
    location.enteredViaWidget = enteredViaWidget;
  }
  return {
    type: LOCATION_CHANGE,
    payload: { location, action },
  };
};

function updateLocation(method) {
  return (...args) => ({
    type: CALL_HISTORY_METHOD,
    payload: { method, args },
  });
}

export const push = updateLocation("push");
export const replace = updateLocation("replace");
export const go = updateLocation("go");
export const goBack = updateLocation("goBack");
export const goForward = updateLocation("goForward");
export const back = updateLocation("back");
export const forward = updateLocation("forward");
