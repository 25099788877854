import React from 'react'
import Loader from './Loader'

const PageLoader = () => (
  <div className="page-loader text-center mt-45">
    <Loader />
  </div>
)

export default PageLoader
