import { produce } from "immer";
import {
  CLEAR_CART,
  ADD_RATES_TO_CART,
  SET_CART_URL,
  SET_CART_REQUIRED_FIELDS,
} from "../types/cart.types";

const INITIAL_STATE = {
  items: [],
  url: "",
  timestamp: null,
  requiredFields: null,
};

export { INITIAL_STATE };
export default produce((draft, action) => {
  switch (action.type) {
    case CLEAR_CART:
      return INITIAL_STATE;
    case SET_CART_URL:
      draft.url = action.payload;
      return draft;
    case ADD_RATES_TO_CART:
      draft.items = action.payload;
      draft.timestamp = new Date().getTime();
      return draft;
    case SET_CART_REQUIRED_FIELDS:
      draft.requiredFields = action.payload;
      return draft;
    default:
      return draft;
  }
}, INITIAL_STATE);
