import axios from "axios";
import jwtDecode from "jwt-decode";
import qs from "qs";
import BugsnagManager from "../../BugsnagManager";
import reduxStore from "../../store/store";
import { setToast, logout } from "../../store/actions";
import Axios from "axios";
import { TABS as PAYG_TABS } from "../ReservationPages/PayAsYouGo/payg_routes";

const NUM_MINS_IN_DAY = 1440;

function shouldIgnoreNetworkError(error) {
  const url = error?.config?.url || "";
  const code = `${error?.response?.data?.code || -1}`;
  const status = error.response?.status || -1;
  const method = error.response?.config?.method;
  const message = error.message || "";
  const networkErrorMessage = error?.response?.data?.Message || "";
  let shouldIgnore = false;

  if (
    url.includes("/subscription/shop/locationRates") &&
    ["LAZPORTAL0516", "LAZPORTAL0800"].includes(code)
  ) {
    shouldIgnore = true;
  } else if (
    url.includes("/account/registration/login") &&
    status === 500 &&
    code === "9999"
  ) {
    shouldIgnore = true;
  } else if (message === "Network Error") {
    shouldIgnore = true;
  } else if (message === "timeout of 0ms exceeded") {
    // if a request has been cancelled (like multiple GET multiple rates calls on a map)
    shouldIgnore = true;
  } else if ([401, 403].includes(status)) {
    shouldIgnore = true;
  } else if (
    url.includes("/subscription/subscription/save") &&
    error?.validationErrors?.length
  ) {
    // if there were valid subscription errors, like invalid payment method
    shouldIgnore = true;
  } else if (
    url.includes("/Rate/GetRate") &&
    `${networkErrorMessage}` === "141"
  ) {
    // Parking end time cannot be less or equals current time
    shouldIgnore = true;
  } else if (
    url.includes("/subscription/subscription/save") &&
    `${code}` === "LAZPORTAL05335"
  ) {
    // Failed to activate as there is already an active subscription for the location
    shouldIgnore = true;
  } else if (
    url.includes("/subscription/account/payment/method/") &&
    method === "delete" &&
    `${code}` === "SUB3120"
  ) {
    // card is attached to a subscription
    shouldIgnore = true;
  } else if (
    url.includes("SpecialProgram/GetProgramEnrollments") &&
    `${code}` === "133"
  ) {
    // if an invalid email was used
    shouldIgnore = true;
  } else if (
    url.includes("/Reservations/GetReservationsInfoByAccountId") &&
    `${networkErrorMessage}` === "125"
  ) {
    // if an invalid email was used
    shouldIgnore = true;
  } else if (
    url.includes("ParkingLocation/GetLocationFields") &&
    `${code}` === "157"
  ) {
    shouldIgnore = true;
  }
  if (shouldIgnore) {
    console.warn("Ignoring network error", error);
  }
  return shouldIgnore;
}

class AxiosManager {
  constructor() {
    this.requestQueue = [];
    this.queueIsRunning = false;
    this.allowedStatuses = [400, 404, 408, 409, 422, 426, 502, 503];
    this.unauthenticatedStatuses = [401, 403];
    this.criticalStatuses = [500];

    const accessToken = this.getAccessToken();

    this.instance = axios.create({
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
        Accept: "application/json",
        "X-Api-Key": process.env.REACT_APP_API_KEY,
        "X-Tenant": process.env.REACT_APP_TENANT,
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        if (!config.headers?.Authorization) {
          delete config.headers.Authorization;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        try {
          let requestData = {};
          let data = JSON.parse(error?.response?.config?.data || "{}");
          if (data.password) {
            data.password = "SCRUBBED";
          }
          try {
            requestData = {
              message: error.message,
              responseData: error.response?.data,
              data,
              requestHeaders: error.request?.config?.headers,
              responseHeaders: error.response?.config?.headers,
              url: error.response?.config?.url,
              method: error.response?.config?.method,
              status: error.response?.status,
              "timedOut?": error.response?.request._timedOut,
              "aborted?": error.response?.request._aborted,
              "hasError?": error.response?.request._hasError,
            };
            const [url, queryParams] = error.config?.url?.split?.("?") || [
              "",
              "",
            ];
            if (url) {
              const params = qs.parse(queryParams);
              const message = `${url} returned ${
                error.response?.status || error.message
              }`;
              const hash = `${url} returned ${
                error.response?.status || error.message
              }`;
              if (!shouldIgnoreNetworkError(error)) {
                let curlCommand = "";
                if (error?.config && error?.request) {
                  try {
                    const method = error.config.method.toUpperCase();
                    const url = error.config.url;
                    const headers = error.config.headers;
                    const data = error.config.data;
                    curlCommand = `curl -X ${method} "${url}"`;
                    for (const [key, value] of Object.entries(headers)) {
                      curlCommand += ` -H "${key}: ${value}"`;
                    }
                    if (data) {
                      curlCommand += ` -d '${data}'`;
                    }
                  } catch (error) {
                    console.warn(
                      "Unable to generate a curl command for error",
                      error?.message
                    );
                  }
                }
                BugsnagManager.notify(new Error(message), {
                  context: hash,
                  metadata: {
                    curl: curlCommand || "",
                    Request: {
                      params,
                      ...requestData,
                    },
                    Response: error.response?.data,
                  },
                });
              }
            }
          } catch (e) {
            if (!Axios.isCancel(e)) {
              console.warn(e);
              this.log("Unable to log network request error", e);
              BugsnagManager.notify(
                new Error("Unable to log Network Request Error"),
                {
                  context: "Unable to log Network Request Error",
                  metadata: {
                    Request: {
                      ...requestData,
                      errorMessage: error.message,
                    },
                    Response: error.response?.data,
                  },
                }
              );
            }
          }
          return Promise.reject(error);
        } catch (e) {
          this.log("Could not handle axios error interceptor", e);
          BugsnagManager.notify(e, {
            context: "Could not handle axios error interceptor",
          });
          return Promise.reject(error);
        }
      }
    );
  }

  log = (message, error) => {
    // if (error) {
    //   console.warn(message, error);
    // } else {
    //   console.log(message);
    // }
    BugsnagManager.leaveBreadcrumb(message);
  };

  getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  setRefreshToken = (token) => {
    return localStorage.setItem("refresh_token", token);
  };

  getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  setAccessToken = (token) => {
    this.instance.defaults.headers.Authorization = `Bearer ${token}`;
    return localStorage.setItem("access_token", token);
  };

  isSessionValid = () => {
    return !!this.getAccessToken();
  };

  handleTokenRefreshLogout = () => {
    let redirectUrl = "";
    const previousRoute =
      window.location.href.replace(window.location.origin, "") || "";
    if (
      !previousRoute.includes("?redirect") &&
      !previousRoute.includes("/elogin") &&
      !previousRoute.includes("/login")
    ) {
      redirectUrl = `&redirect=${previousRoute}`;
    }
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.href =
      process.env.REACT_APP_CONTEXT_ROOT + "/login?type=login" + redirectUrl;
  };

  logout = (options = {}) => {
    const { reason, alertUser = false } = options;
    const dispatch = reduxStore.store.dispatch;

    this.emptyQueue();
    if (this.instance?.defaults?.headers?.Authorization) {
      this.instance.defaults.headers.Authorization = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    }
    if (alertUser) {
      dispatch(
        setToast({
          message: "For security purposes, you have been signed out.",
          type: "E",
        })
      );
    }

    const devHost = "localhost:3000";
    const stgHost = "stg.grswebui.thinxsoftware.com";
    const productionHost = "go.lazparking.com";

    const state = reduxStore.store.getState() || {};
    const isLoginViaNu = state.user?.isLoginViaNu;
    const isLoginViaEmu = state.user?.isLoginViaEmu;

    if (window.location.host === productionHost && isLoginViaNu) {
      window.location.replace(
        "https://neuidmsso.neu.edu/idp/profile/cas/logout"
      );
    } else if (
      (window.location.host === devHost || window.location.host === stgHost) &&
      isLoginViaNu
    ) {
      window.location.replace(
        "https://neuidmssotest.neu.edu/idp/profile/cas/logout"
      );
    } else if (window.location.host === productionHost && isLoginViaEmu) {
      window.location.replace("https://netid.emich.edu/cas/logout");
    } else if (
      (window.location.host === devHost || window.location.host === stgHost) &&
      isLoginViaEmu
    ) {
      window.location.replace("https://netidtest.emich.edu/cas/logout");
    } else {
      window.location.replace(
        process.env.REACT_APP_CONTEXT_ROOT + "/login?type=login"
      );
    }
    this.log(`Logging out: ${reason}`);
    dispatch(logout());
  };

  refreshToken = async () => {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.log("Attempted to refresh token with no refresh_token");
      return false;
    }
    const decodedToken = jwtDecode(refreshToken);
    const exp = decodedToken.exp * 1000;
    const minuteDiff = Math.floor(
      ((exp - Date.now()) / 1000 / 60 / 60 / 24) * NUM_MINS_IN_DAY
    );
    if (minuteDiff <= 0) {
      const expDate = new Date(exp);
      this.log(
        `Refresh token expired ${expDate.toLocaleDateString()} at ${expDate.toLocaleTimeString()}`
      );
      return false;
    } else {
      this.log(`Refresh token expires in ${minuteDiff} minutes(s)`);
    }
    let response;
    try {
      response = await axios.post(
        process.env.REACT_APP_GRS_URL +
          "/rest/account/registration/token/refresh",
        {
          refresh_token: refreshToken,
        },
        {
          headers: {
            "X-Api-Key": process.env.REACT_APP_API_KEY,
            "X-Tenant": process.env.REACT_APP_TENANT,
          },
        }
      );
    } catch (error) {
      BugsnagManager.notify(new Error("Unexpected response in user refresh"), {
        context: "Unexpected response in user refresh",
        metadata: {
          response: response?.data,
        },
      });
    }
    if (response?.data?.access_token) {
      this.setAccessToken(response.data.access_token);
      this.setRefreshToken(response.data.refresh_token); // probably not needed
      this.log("JWT was refreshed");
      return response.data.access_token;
    } else {
      if (!Object.values(PAYG_TABS).includes(window.location.pathname)) {
        this.handleTokenRefreshLogout();
        this.log("JWT was not refreshed");
      }
    }
    return false;
  };

  emptyQueue = () => {
    this.requestQueue = [];
    this.queueIsRunning = false;
  };

  enqueue = (request, config) => {
    const promise = new Promise((resolve, reject) => {
      this.requestQueue.push({ request, config, resolve, reject });
    });
    this.dequeue();
    return promise;
  };

  async dequeue() {
    if (this.queueIsRunning) {
      return false;
    }
    const item = this.requestQueue.shift();
    if (!item) {
      return false;
    }
    try {
      this.queueIsRunning = true;
      let shouldContinue = true;
      if (this.instance?.defaults?.headers?.Authorization) {
        let decodedJwt = null;
        try {
          decodedJwt = jwtDecode(
            this.instance?.defaults?.headers?.Authorization.replace(
              "Bearer ",
              ""
            )
          );
        } catch (error) {
          this.log("Unable to decode jwt", error);
          BugsnagManager.notify(error, {
            context: "Unable to decode jwt",
            metadata: {
              currentJwt: this.instance?.defaults?.headers?.Authorization,
            },
          });
          this.logout({ reason: "Unable to decode jwt", alertUser: true });
          shouldContinue = false;
          return false;
        }
        if (decodedJwt?.exp) {
          const exp = decodedJwt.exp * 1000;
          const minuteDiff = Math.floor(
            ((exp - Date.now()) / 1000 / 60 / 60 / 24) * NUM_MINS_IN_DAY
          );
          if (minuteDiff <= 0) {
            const expDate = new Date(exp);
            this.log(
              `JWT expired ${expDate.toLocaleDateString()} at ${expDate.toLocaleTimeString()}`
            );
            const response = await this.refreshToken();
            if (!response) {
              this.logout({
                reason: "Unexpected response in user refresh",
                alertUser: true,
              });
              shouldContinue = false;
              return false;
            }
          } else {
            this.log(`JWT expires in ${minuteDiff} minutes(s)`);
          }
        } else {
          this.log("Exp property not found on decodedJwt");
        }
      }
      if (shouldContinue) {
        item
          .request()
          .then((response) => {
            item.resolve(response);
          })
          .catch((error) => {
            item.reject(error);
          });
      }
    } catch (error) {
      item.reject(error);
    }
    this.queueIsRunning = false;
    this.dequeue();
    return true;
  }

  convertParams = (params) => {
    return {
      url: params[0],
      ...(params[1] || {}),
    };
  };

  get = (...params) => {
    return this.enqueue(() => {
      return this.instance.get(...params);
    }, this.convertParams(params));
  };

  put = (...params) => {
    return this.enqueue(() => {
      return this.instance.put(...params);
    }, this.convertParams(params));
  };

  patch = (...params) => {
    return this.enqueue(() => {
      return this.instance.patch(...params);
    }, this.convertParams(params));
  };

  post = (...params) => {
    return this.enqueue(() => {
      return this.instance.post(...params);
    }, this.convertParams(params));
  };

  del = (...params) => {
    return this.enqueue(() => {
      return this.instance.delete(...params);
    }, this.convertParams(params));
  };
}

export default new AxiosManager();
