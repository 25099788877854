import SearchUtil from "./SearchUtil";

export function fetchWindowLocationInformation(location) {
  if (location) {
    let searchTerm = "";
    if (location.pathname.includes("search-results")) {
      const pathname = location.pathname.replace(
        process.env.REACT_APP_CONTEXT_ROOT,
        ""
      );
      searchTerm = pathname.split("/").filter((x) => x)[1] || "";
      if (searchTerm) {
        searchTerm = decodeURIComponent(searchTerm);
      }
    }
    const query = {};
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      for (let [key, value] of searchParams.entries()) {
        query[key] = decodeURIComponent(value);
      }
    }

    // entered without params means we only have a location on location.search
    const enteredViaWidget = query.wt || query.wk;
    const differenceInMinutes =
      SearchUtil.calculateMinuteDifferenceFromCurrentTime(query.start);
    let isParkingStartingNow = true;
    if (
      query.sc === "t2p" ||
      query.sc === "pnqr" ||
      Object.keys(query).length === 0
    ) {
      isParkingStartingNow = true;
    } else if (!query.start && !query.end) {
      isParkingStartingNow = true;
    } else if (differenceInMinutes === 0) {
      isParkingStartingNow = true;
    } else {
      isParkingStartingNow = false;
    }

    return {
      searchTerm,
      query,
      differenceInMinutes,
      enteredViaWidget,
      isParkingStartingNow,
    };
  } else {
    return {};
  }
}
