import "./styles.scss";
import React from "react";
import backgroundImage from "../../../assets/image/laz_blue_bg.png";
import warningIcon from "../../../assets/image/warning_black.svg";
import LAZButton from "../Segments/LAZButton";

function ErrorOverlay() {
  return (
    <div className="error-overlay-container">
      <div className="error-overlay-inner-container">
        <div className="error-overlay-title-container">
          <img
            src={warningIcon}
            alt="warning"
            className="error-overlay-warning-icon"
          />
          <div className="error-overlay-title">
            Oh snap! Something went wrong.
          </div>
        </div>
        <div className="error-overlay-subtitle">
          We apologize for the inconvenience. Our application has encountered an
          unexpected error and our tech team has been notified.
        </div>
        <br />
        <div className="error-overlay-subtitle">
          To help us investigate the problem further, we kindly request that you
          perform the following steps:
          <ul>
            <li>
              <strong>Refresh the page:</strong> In some cases, a simple page
              refresh can resolve the issue.
            </li>
            <li>
              <strong>Clear your browser cache:</strong> Stale or corrupted
              cache files can sometimes cause conflicts. Clear your browser
              cache and try again.
            </li>
            <li>
              <strong>Check your connection:</strong> Ensure that you have a
              stable internet connection. Temporary network interruptions can
              occasionally lead to application crashes.
            </li>
          </ul>
        </div>
        <br />
        <div className="error-overlay-footer">
          <div className="btn-group w-100">
            <LAZButton
              onClick={() => {
                window.history.go(-1);
              }}
            >
              Go Home
            </LAZButton>
          </div>
        </div>
      </div>
      <img
        src={backgroundImage}
        alt="background"
        className="background-full-image"
      />
    </div>
  );
}

export default ErrorOverlay;
