import Bugsnag from "@bugsnag/js";

class BugsnagManager {
  leaveBreadcrumb = (name, metadata) => {
    Bugsnag.leaveBreadcrumb(name, metadata);
  };

  setUser = (userId, userEmail, userName) => {
    Bugsnag.setUser(userId, userEmail, userName);
  };

  notify = (e, options = {}) => {
    const {
      unhandled = false,
      overrideGroupingHash = true,
      groupingHash,
      context,
      metadata = null,
    } = options;

    // Ignore network errors (low to no network)
    if ((e?.message || "").toLowerCase().includes("chunk")) {
      Bugsnag.notify(new Error("Chunking Error"), function (event) {
        event.context = "Chunking Error";
        event.unhandled = unhandled;
        if (overrideGroupingHash) {
          event.groupingHash = groupingHash || context;
        }
      });
    } else if (e?.message !== "Network Error") {
      Bugsnag.notify(e, function (event) {
        event.context = context;
        event.unhandled = unhandled;
        if (overrideGroupingHash) {
          event.groupingHash = groupingHash || context;
        }
        if (metadata) {
          for (var key in metadata) {
            event.addMetadata(key, metadata[key]);
          }
        }
      });
    }
  };
}

export default new BugsnagManager();
