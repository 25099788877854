/* eslint-disable consistent-return,indent */
import { CALL_HISTORY_METHOD } from "../types/location.types";

function appendBasename(location, basename) {
  if (typeof location === "string" && !location.startsWith(basename)) {
    return basename + location;
  }
  if (
    typeof location === "object" &&
    !!location.pathname &&
    !location.pathname.startsWith(basename)
  ) {
    return { ...location, pathname: basename + location.pathname };
  }
  return location;
}

export const createRouterMiddleware =
  ({ history, showHistoryAction, basename }) =>
  () =>
  (next) =>
  (action) => {
    if (action.type !== CALL_HISTORY_METHOD) {
      return next(action);
    }
    const method = action.payload.method;
    // @ts-ignore
    const args = action.payload.args;

    // eslint-disable-next-line default-case
    switch (method) {
      case "push": {
        let callArgs = args;
        if (basename && args.length > 0) {
          callArgs = [appendBasename(args[0], basename), ...args.slice(1)];
        }
        history.push(...callArgs);
        break;
      }
      case "replace": {
        let callArgs = args;
        if (basename && args.length > 0) {
          callArgs = [appendBasename(args[0], basename), ...args.slice(1)];
        }
        history.replace(...callArgs);
        break;
      }
      case "go":
        history.go(...args);
        break;
      case "back":
      case "goBack":
        // @ts-ignore
        history.goBack && history.goBack(...args);
        //@ts-ignore //support history 5.x
        history.back && history.back(...args);
        break;
      case "forward":
      case "goForward":
        // @ts-ignore
        history.goForward && history.goForward(...args);
        //@ts-ignore //support history 5.x
        history.forward && history.forward(...args);
        break;
    }
    if (showHistoryAction) return next(action);
  };
