import { produce } from "immer";
import {
  SET_AIRPORT_DATA_BEGIN,
  SET_AIRPORT_DATA_ERROR,
  SET_AIRPORT_DATA_SUCCESS,
  SET_UPDATED_LOCATIONS,
  SET_SELECTED_RATE,
  SET_SELECTED_LOCATION,
  SET_RESERVATION,
  SET_RESERVATION_CONFIRMATION,
  SET_IS_SEARCHING,
} from "../types/airport.types";

const mergeAndSortLocations = (incomingLocations, locations) => {
  let mergedLocations = incomingLocations.map((incomingLocation) => {
    const prevLocation =
      (locations || []).find((prevLocation) => {
        return prevLocation.LocNo === incomingLocation.LocNo;
      }) || {};
    return {
      ...prevLocation,
      ...incomingLocation,
    };
  });

  mergedLocations.sort((a, b) => {
    if (!a.locationRate?.Amount) return 1;
    if (!b.locationRate?.Amount) return -1;
    return a.locationRate?.Amount - b.locationRate?.Amount;
  });

  return mergedLocations;
};

const INITIAL_STATE = {
  loading: true,
  client: null,
  locationFields: null,
  languageLabels: null,
  locations: null,
  selectedRate: null,
  selectedLocation: null,
  reservation: null,
  reservationConfirmation: null,
  isSearching: false,
};

export { INITIAL_STATE };
export default produce((draft, action) => {
  switch (action.type) {
    case SET_AIRPORT_DATA_BEGIN:
      draft.loading = true;
      return draft;
    case SET_AIRPORT_DATA_SUCCESS:
      draft.loading = false;
      draft.languageLabels =
        action.payload.languageLabels || INITIAL_STATE.languageLabels;
      draft.locations = action.payload.locations
        ? mergeAndSortLocations(action.payload.locations, draft.locations)
        : INITIAL_STATE.locations;
      draft.locationFields =
        action.payload.locationFields || INITIAL_STATE.locationFields;
      draft.client = action.payload.client || INITIAL_STATE.client;
      return draft;
    case SET_AIRPORT_DATA_ERROR:
      draft.loading = false;
      return draft;
    case SET_SELECTED_RATE:
      draft.selectedRate = action.payload;
      return draft;
    case SET_SELECTED_LOCATION:
      draft.selectedLocation = action.payload;
      return draft;
    case SET_UPDATED_LOCATIONS:
      draft.locations = action.payload;
      return draft;
    case SET_RESERVATION:
      draft.reservation = action.payload;
      return draft;
    case SET_RESERVATION_CONFIRMATION:
      draft.reservationConfirmation = action.payload;
      return draft;
    case SET_IS_SEARCHING:
      draft.isSearching = action.payload;
      return draft;
    default:
      return draft;
  }
}, INITIAL_STATE);
