import React from "react";
import { Router } from "react-router-dom";
import Routes from "./Routes";
import { connect } from "react-redux";
import { fetchLanguageData } from "./store/actions";
import { InitGoogleAnalytics } from "./components/Utils/DataCol/Analytics/GoogleAnalytics";
import { InitGoogleTagManager } from "./components/Utils/DataCol/Tags/GoogleTagManager";
import pjson from "../package.json";
import store from "./store/store";
import "bootstrap";

global.hasHandledInitialIdleEvent = false;
global.debugMap = localStorage.getItem("debugBoundingBox");

function App({ fetchLanguageData }) {
  const { history } = store;

  React.useEffect(() => {
    if (!pjson?.version) {
      console.warn("pjson.version not defined");
    } else {
      console.log(process.env.REACT_APP_ENVIRONMENT, `v${pjson?.version}`);
    }
    fetchLanguageData();

    const isAirport = window.location.pathname.includes("airport");
    // Airport flow uses separate cookie consent flow, so GA and tag manager
    // are initialized post-cookie consent.
    if (process.env.NODE_ENV !== "development" && !isAirport) {
      InitGoogleTagManager();
      InitGoogleAnalytics();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // specify base href from env variable 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only
  // const basename =
  //   process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";
  return (
    <Router basename="/" history={history}>
      <Routes />
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    language: state.language?.data,
  };
}

const mapDispatchToProps = {
  fetchLanguageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
