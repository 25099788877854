import { produce } from "immer";
import {
  SAVE_PAYG_BRANDING,
  SET_PAYG_LOCATION_BEGIN,
  SET_PAYG_LOCATION_SUCCESS,
  SET_PAYG_LOCATION_ERROR,
  GET_PAYG_USER_BEGIN,
  GET_PAYG_USER_ERROR,
  SET_PAYG_FORM_DATA,
  SET_PAYG_PAYMENT_METHOD_SUCCESS,
  VERIFY_PAYG_CREDENTIAL_BEGIN,
  VERIFY_PAYG_CREDENTIAL_SUCCESS,
  VERIFY_PAYG_CREDENTIAL_ERROR,
  SET_AUTHENTICATE_SHEET_OPEN,
  START_PAYG_SESSION_BEGIN,
  START_PAYG_SESSION_SUCCESS,
  START_PAYG_SESSION_ERROR,
  END_PAYG_SESSION_BEGIN,
  END_PAYG_SESSION_SUCCESS,
  END_PAYG_SESSION_ERROR,
  CONTINUE_PAYG_SESSION_BEGIN,
  CONTINUE_PAYG_SESSION_SUCCESS,
  CONTINUE_PAYG_SESSION_ERROR,
  GET_PAYG_SESSION_BEGIN,
  GET_PAYG_SESSION_SUCCESS,
  GET_PAYG_SESSION_ERROR,
  GET_PAYG_SESSIONS_BEGIN,
  GET_PAYG_SESSIONS_SUCCESS,
  GET_PAYG_SESSIONS_ERROR,
  GET_PAYG_USER_SUCCESS,
  VERIFY_AUTH_CODE_BEGIN,
  VERIFY_AUTH_CODE_SUCCESS,
  VERIFY_AUTH_CODE_ERROR,
  SET_PAYG_PAYMENT_METHOD_BEGIN,
  SET_PAYG_PAYMENT_METHOD_ERROR,
} from "../types/payg.types";
import logo from "../../assets/image/laz-logo.svg";

const INITIAL_STATE = {
  formData: {
    // mobile authentication form
    mobileNumber: "",
    countryCode: "+1",
    validationContext: "",
    code: "",

    // add vehicle form
    // note: if making changes here, ensure pre-population logic in
    // GET_PAYG_USER_SUCCESS is also updated
    isRentalVehicle: false,
    parkerLicensePlate: "",
    parkerLicensePlateState: "",

    // add payment method form
    // note: if making changes here, ensure pre-population logic in
    // GET_PAYG_USER_SUCCESS is also updated
    emvData: "", // only used for pre-populating
    id: "",
    address: "",
    postalCode: "",
    expireMonth: "",
    expireYear: "",
    lastFour: "",
    token: "",
  },
  authenticateSheetOpen: false,
  location: {
    data: null,
    loading: true,
    error: false,
  },
  session: {
    accessToken: null,
    accountId: null,
  },
  user: {
    data: null,
    loading: false,
    error: false,
  },
  credentials: {
    loading: false,
    error: true,
    data: null,
  },
  sessions: {
    data: [],
    loading: false,
    error: false,
  },
  branding: {
    loading: false,
    primaryColor: "#007dba",
    secondaryColor: "#444444",
    logo: logo,
  },
};

export { INITIAL_STATE };
export default produce((draft, action) => {
  switch (action.type) {
    case SET_AUTHENTICATE_SHEET_OPEN:
      draft.authenticateSheetOpen = action.payload;
      return draft;
    case SET_PAYG_LOCATION_BEGIN:
      draft.location.loading = true;
      draft.location.error = false;
      return draft;
    case SET_PAYG_LOCATION_SUCCESS:
      draft.location.loading = false;
      draft.location.error = false;
      draft.location.data = action.payload;
      return draft;
    case SET_PAYG_LOCATION_ERROR:
      draft.location.data = INITIAL_STATE.location;
      draft.location.loading = false;
      draft.location.error = true;
      return draft;
    case SET_PAYG_FORM_DATA:
      draft.formData = {
        ...(draft.formData || {}),
        ...(action.payload || {}),
      };
      return draft;
    case GET_PAYG_USER_BEGIN:
      draft.user.loading = true;
      draft.user.error = false;
      return draft;
    case GET_PAYG_USER_SUCCESS:
      draft.user.loading = false;
      draft.user.error = false;
      draft.user.data = action.payload;
      if (
        action.payload.extended?.fieldMap?.vehicles?.fieldList?.length &&
        !draft.formData.parkerLicensePlate &&
        !draft.formData.parkerLicensePlateState
      ) {
        console.log(
          "Pre-populating vehicle",
          action.payload.extended.fieldMap.vehicles.fieldList[0].fieldMap
            .vehicleId.value
        );
        draft.formData.isRentalVehicle =
          action.payload.extended.fieldMap.vehicles.fieldList[0].fieldMap.rental.value;
        draft.formData.parkerLicensePlate =
          action.payload.extended.fieldMap.vehicles.fieldList[0].fieldMap.plateNumber.value;
        draft.formData.parkerLicensePlateState =
          action.payload.extended.fieldMap.vehicles.fieldList[0].fieldMap.state.value;
      }
      if (action.payload.paymentMethods?.length) {
        console.log(
          "Pre-populating payment method",
          action.payload.paymentMethods[0].id
        );
        draft.formData.id = action.payload.paymentMethods[0].id;
        draft.formData.address = action.payload.paymentMethods[0].address;
        draft.formData.postalCode = action.payload.paymentMethods[0].postalCode;
        draft.formData.expireMonth =
          action.payload.paymentMethods[0].expireMonth;
        draft.formData.expireYear = action.payload.paymentMethods[0].expireYear;
        draft.formData.lastFour = action.payload.paymentMethods[0].lastFour;
        draft.formData.token = action.payload.paymentMethods[0].token;
      }
      return draft;
    case GET_PAYG_USER_ERROR:
      draft.user.loading = false;
      draft.user.error = true;
      return draft;
    case START_PAYG_SESSION_BEGIN:
      draft.sessions.loading = true;
      draft.sessions.error = false;
      return draft;
    case START_PAYG_SESSION_SUCCESS:
      draft.sessions.loading = false;
      draft.sessions.error = false;
      if (draft.formData.isRentalVehicle) {
        draft.formData.isRentalVehicle = INITIAL_STATE.formData.isRentalVehicle;
        draft.formData.isRentalVehicle =
          INITIAL_STATE.formData.parkerLicensePlate;
        draft.formData.isRentalVehicle =
          INITIAL_STATE.formData.parkerLicensePlateState;
      }
      return draft;
    case START_PAYG_SESSION_ERROR:
      draft.sessions.loading = false;
      draft.sessions.error = true;
      return draft;
    case END_PAYG_SESSION_BEGIN:
      draft.sessions.loading = true;
      draft.sessions.error = false;
      return draft;
    case END_PAYG_SESSION_SUCCESS:
      draft.sessions.loading = false;
      draft.sessions.error = false;
      return draft;
    case END_PAYG_SESSION_ERROR:
      draft.sessions.loading = false;
      draft.sessions.error = true;
      return draft;
    case CONTINUE_PAYG_SESSION_BEGIN:
      draft.sessions.loading = true;
      draft.sessions.error = false;
      return draft;
    case CONTINUE_PAYG_SESSION_SUCCESS:
      draft.sessions.loading = false;
      draft.sessions.error = false;
      return draft;
    case CONTINUE_PAYG_SESSION_ERROR:
      draft.sessions.loading = false;
      draft.sessions.error = true;
      return draft;
    case SAVE_PAYG_BRANDING:
      draft.branding = {
        ...(draft.branding || {}),
        ...action.payload,
      };
      return draft;
    case SET_PAYG_PAYMENT_METHOD_BEGIN:
      return draft;
    case SET_PAYG_PAYMENT_METHOD_SUCCESS:
      return draft;
    case SET_PAYG_PAYMENT_METHOD_ERROR:
      return draft;
    case VERIFY_PAYG_CREDENTIAL_BEGIN:
      draft.credentials.loading = true;
      draft.credentials.error = false;
      return draft;
    case VERIFY_PAYG_CREDENTIAL_SUCCESS:
      draft.credentials.loading = false;
      draft.credentials.error = false;
      draft.credentials.data = action.payload;
      return draft;
    case VERIFY_PAYG_CREDENTIAL_ERROR:
      draft.credentials.loading = false;
      draft.credentials.error = true;
      return draft;
    case GET_PAYG_SESSION_BEGIN:
      draft.sessions.loading = true;
      draft.sessions.error = false;
      return draft;
    case GET_PAYG_SESSION_SUCCESS:
      // TODO: might need to delete this since everything is fetched in GetReservationsInfoByAccountId
      draft.sessions.loading = false;
      draft.sessions.error = false;
      return draft;
    case GET_PAYG_SESSION_ERROR:
      draft.sessions.loading = false;
      draft.sessions.error = true;
      return draft;
    case GET_PAYG_SESSIONS_BEGIN:
      draft.sessions.loading = true;
      draft.sessions.error = false;
      return draft;
    case GET_PAYG_SESSIONS_SUCCESS:
      draft.sessions.loading = false;
      draft.sessions.error = false;
      draft.sessions.data = action.payload;
      return draft;
    case GET_PAYG_SESSIONS_ERROR:
      draft.sessions.loading = false;
      draft.sessions.error = true;
      return draft;
    case VERIFY_AUTH_CODE_BEGIN:
      return draft;
    case VERIFY_AUTH_CODE_SUCCESS:
      draft.session.accessToken = action.payload.keycloakToken;
      draft.session.accountId = action.payload.accountId;
      return draft;
    case VERIFY_AUTH_CODE_ERROR:
      return draft;
    default:
      return draft;
  }
}, INITIAL_STATE);
