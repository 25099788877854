export const geocodeByPlaceId = (placeId, config = {}) => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const { OK } = window.google.maps.GeocoderStatus;
      geocoder.geocode({ placeId, ...config }, (results, status) => {
        if (status !== OK) {
          return reject(status);
        }
        return resolve(results);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const geocodeByLatLng = (latLng, config = {}) => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const { OK } = window.google.maps.GeocoderStatus;
      geocoder.geocode({ location: latLng, ...config }, (results, status) => {
        if (status !== OK) return reject(status);

        return resolve(results);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const geocodeByAddress = (address, config = {}) => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const { OK } = window.google.maps.GeocoderStatus;
      geocoder.geocode({ address, ...config }, (results, status) => {
        if (status !== OK) {
          return reject(
            new Error(`geocodeByAddress status was not OK: ${status}`)
          );
        }

        return resolve(results);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getLatLng = (result) =>
  new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result?.geometry?.location?.lat?.() || 0,
        lng: result?.geometry?.location?.lng?.() || 0,
      };
      return resolve(latLng);
    } catch (e) {
      return reject(e);
    }
  });

function calculateMapWidth() {
  const windowWidth = window.innerWidth;
  const listComponent = document.getElementById("map-list-component");
  const listComponentWidth = listComponent?.clientWidth || 0;
  if (windowWidth < 768) {
    return windowWidth;
  } else if (windowWidth < 1200) {
    return windowWidth - listComponentWidth;
  } else {
    return windowWidth - listComponentWidth - 256;
  }
}

const HEADER_HEIGHT = 60;
const MAP_HEADER_HEIGHT = 54;

export function calculateDefaultZoomLevel({
  placeIsEstablishment = false,
  isNearMe,
  north,
  south,
  east,
  west,
}) {
  // if (placeIsEstablishment) {
  //   console.log("Using establishment default zoom value of 16");
  //   return 16;
  // }
  if (isNearMe) {
    console.log("Using isNearMe default zoom value of 15");
    return 15;
  }
  if (!north && !south && !east && !west) {
    console.warn("No boundaries provided to calculateDefaultZoomLevel");
    return 15;
  }

  const mapEl = document.getElementById("map");
  const width = mapEl?.clientWidth || calculateMapWidth();
  const height =
    mapEl?.clientHeight ||
    window.innerHeight - (HEADER_HEIGHT + MAP_HEADER_HEIGHT);

  // Calculate horizontal and vertical distances
  const horizontalDistance = Math.abs(east - west);
  const verticalDistance = Math.abs(north - south);

  // Calculate zoom level based on screen size and bounding box coordinates
  const GLOBE_WIDTH = 256; // Width of the entire map at zoom level 0

  const zoomFactorHorizontal =
    ((width / GLOBE_WIDTH) * 360) / horizontalDistance;
  const zoomLevelHorizontal = Math.log2(zoomFactorHorizontal);
  const zoomFactorVertical = ((height / GLOBE_WIDTH) * 270) / verticalDistance;
  const zoomLevelVertical = Math.log2(zoomFactorVertical);

  let zoomLevel = Math.min(zoomLevelHorizontal, zoomLevelVertical);

  if (zoomLevel < 14) {
    zoomLevel = 14;
  } else if (zoomLevel === Infinity) {
    zoomLevel = 17;
  } else if (zoomLevel > 18) {
    zoomLevel = 18;
  }

  // add some padding to markers that
  // are rendered on the map by zooming out slightly
  return zoomLevel - 0.1;
}

// Helper function for converting a bounding box to an actual
// GeoJSON feature for use in online visualizers like https://geojson.io/
// https://geojson.org/
export function arrayToGeoJSON(coordinates) {
  const [neLat, neLon, swLat, swLon] = coordinates;
  const geojsonFeature = {
    type: "Feature",
    properties: {
      stroke: `rgb(${125 * Math.random()}, ${125 * Math.random()}, ${
        125 * Math.random()
      })`,
      "stroke-width": 2,
      "stroke-opacity": 0.5,
      fill: `rgb(${255 * Math.random()}, ${255 * Math.random()}, ${
        255 * Math.random()
      })`,
      "fill-opacity": 0.2,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [neLon, neLat],
          [swLon, neLat],
          [swLon, swLat],
          [neLon, swLat],
          [neLon, neLat],
        ],
      ],
    },
  };
  return geojsonFeature;
}
