import {
  SET_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT_BEGIN,
  UPDATE_USER_ACCOUNT_SUCCESS,
  UPDATE_USER_ACCOUNT_ERROR,
  UPDATE_USER_ADDRESS_BEGIN,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_ERROR,
  SET_EMU_LOGIN_STATUS,
  SET_NU_LOGIN_STATUS,
  EMU_LOGOUT,
  NU_LOGOUT,
  SET_SUBSCRIPTION_TO_MODIFY,
  SET_RESERVATION_TO_MODIFY,
  SET_SUBSCRIPTION_ID,
  SET_QR_CODE_TOKEN,
  LOGOUT,
  STORE_PAYMENT_METHOD,
  STORE_PURCHASE_TYPE,
  SAVE_PAYMENT_METHOD_BEGIN,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_BEGIN,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_BEGIN,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  SAVE_VEHICLE_BEGIN,
  SAVE_VEHICLE_SUCCESS,
  SAVE_VEHICLE_ERROR,
  DELETE_VEHICLE_BEGIN,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  SET_LOYALTY_PROGRAMS,
  SET_REWARD_PROGRAMS,
  DELETE_LOYALTY_PROGRAM,
  UPDATE_USER_PAYMENT_METHOD_BEGIN,
  UPDATE_USER_PAYMENT_METHOD_SUCCESS,
  UPDATE_USER_PAYMENT_METHOD_ERROR,
} from "../types/user.types";

// ACTIONS RELATED TO THE ACCOUNT STATUS

export function setUserAccount(account) {
  return {
    type: SET_USER_ACCOUNT,
    payload: account,
  };
}

export function updateUserAccountBegin() {
  return {
    type: UPDATE_USER_ACCOUNT_BEGIN,
  };
}

export function updateUserAccountSuccess(updatedAccount) {
  return {
    type: UPDATE_USER_ACCOUNT_SUCCESS,
    payload: updatedAccount,
  };
}

export function updateUserAccountError() {
  return {
    type: UPDATE_USER_ACCOUNT_ERROR,
  };
}

export function updateUserAddressBegin() {
  return {
    type: UPDATE_USER_ADDRESS_BEGIN,
  };
}

export function updateUserAddressSuccess(updatedAddress) {
  return {
    type: UPDATE_USER_ADDRESS_SUCCESS,
    payload: updatedAddress,
  };
}

export function updateUserAddressError() {
  return {
    type: UPDATE_USER_ADDRESS_ERROR,
  };
}

export function updateUserPaymentMethodBegin() {
  return {
    type: UPDATE_USER_PAYMENT_METHOD_BEGIN,
  };
}

export function updateUserPaymentMethodSuccess(updatedPaymentMethod) {
  return {
    type: UPDATE_USER_PAYMENT_METHOD_SUCCESS,
    payload: updatedPaymentMethod,
  };
}

export function updateUserPaymentMethodError() {
  return {
    type: UPDATE_USER_PAYMENT_METHOD_ERROR,
  };
}

export function setQRCodeToken(token) {
  return {
    type: SET_QR_CODE_TOKEN,
    payload: token,
  };
}

export function logout(clearAll = true) {
  return {
    type: LOGOUT,
    meta: {
      clearAll,
    },
  };
}

export function setSubscriptionId(subscriptionId) {
  return {
    type: SET_SUBSCRIPTION_ID,
    payload: subscriptionId,
  };
}

export function setSubscriptionToModify(subscription) {
  return {
    type: SET_SUBSCRIPTION_TO_MODIFY,
    payload: subscription,
  };
}

export function setReservationToModify(reservation, policy) {
  return {
    type: SET_RESERVATION_TO_MODIFY,
    payload: { reservation, policy },
  };
}

export function setEmuLoginStatus(isLoginViaEmu) {
  return {
    type: SET_EMU_LOGIN_STATUS,
    payload: isLoginViaEmu,
  };
}

export function setNuLoginStatus(isLoginViaNu) {
  return {
    type: SET_NU_LOGIN_STATUS,
    payload: isLoginViaNu,
  };
}

export function emuLogout() {
  return {
    type: EMU_LOGOUT,
  };
}

export function nuLogout() {
  return {
    type: NU_LOGOUT,
  };
}

export function storePaymentMethod(paymentSelected) {
  return {
    type: STORE_PAYMENT_METHOD,
    payload: paymentSelected,
  };
}

//This is only used for RIB special 'Pay As You Go' program.
export function storePurchaseType(purchaseType) {
  return {
    type: STORE_PURCHASE_TYPE,
    payload: purchaseType,
  };
}

// ACTIONS RELATED TO PAYMENT METHODS

export function savePaymentMethodBegin() {
  return {
    type: SAVE_PAYMENT_METHOD_BEGIN,
  };
}

export function savePaymentMethodSuccess(paymentMethod) {
  return {
    type: SAVE_PAYMENT_METHOD_SUCCESS,
    payload: paymentMethod,
  };
}

export function savePaymentMethodError() {
  return {
    type: SAVE_PAYMENT_METHOD_ERROR,
  };
}

export function deletePaymentMethodBegin() {
  return {
    type: DELETE_PAYMENT_METHOD_BEGIN,
  };
}

export function deletePaymentMethodSuccess(paymentMethodId) {
  return {
    type: DELETE_PAYMENT_METHOD_SUCCESS,
    payload: paymentMethodId,
  };
}

export function deletePaymentMethodError() {
  return {
    type: DELETE_PAYMENT_METHOD_ERROR,
  };
}

export function setDefaultPaymentMethodBegin() {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_BEGIN,
  };
}

export function setDefaultPaymentMethodSuccess(paymentMethodId) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
    payload: paymentMethodId,
  };
}

export function setDefaultPaymentMethodError() {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD_ERROR,
  };
}

// ACTIONS RELATED TO VEHICLES

export function saveVehicleBegin() {
  return {
    type: SAVE_VEHICLE_BEGIN,
  };
}

export function saveVehicleSuccess(vehicle) {
  return {
    type: SAVE_VEHICLE_SUCCESS,
    payload: vehicle,
  };
}

export function saveVehicleError() {
  return {
    type: SAVE_VEHICLE_ERROR,
  };
}

export function deleteVehicleBegin() {
  return {
    type: DELETE_VEHICLE_BEGIN,
  };
}

export function deleteVehicleSuccess(vehicleId) {
  return {
    type: DELETE_VEHICLE_SUCCESS,
    payload: vehicleId,
  };
}

export function deleteVehicleError() {
  return {
    type: DELETE_VEHICLE_ERROR,
  };
}

// ACTIONS RELATED TO LOYALTY A& REWARD PROGRAMS

export function setLoyaltyPrograms(loyaltyPrograms) {
  return {
    type: SET_LOYALTY_PROGRAMS,
    payload: loyaltyPrograms,
  };
}

export function setRewardPrograms(rewardPrograms) {
  return {
    type: SET_REWARD_PROGRAMS,
    payload: rewardPrograms,
  };
}

export function deleteLoyaltyProgram(loyaltyProgram) {
  return {
    type: DELETE_LOYALTY_PROGRAM,
    payload: loyaltyProgram,
  };
}
