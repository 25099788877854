import {
  CLEAR_CART,
  ADD_RATES_TO_CART,
  SET_CART_URL,
  SET_CART_REQUIRED_FIELDS,
} from "../types/cart.types";

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export function addRatesToCart(payload = []) {
  return {
    type: ADD_RATES_TO_CART,
    payload,
  };
}

export function setCartUrl(payload = "") {
  return {
    type: SET_CART_URL,
    payload,
  };
}

export function setCartRequiredFields(payload = null) {
  return {
    type: SET_CART_REQUIRED_FIELDS,
    payload,
  };
}
