import moment from "moment";
import qs from "querystring-es3";
import DataUtil from "./DataUtil";
import reduxStore from "../../store/store";

export default class SearchUtil {
  static findLowestRate(displayRateList = []) {
    let lowestRate = null;
    for (let i = 0; i < displayRateList.length; i++) {
      if (!lowestRate || displayRateList[i].Amount < lowestRate.Amount) {
        lowestRate = displayRateList[i];
      }
    }
    return lowestRate?.Amount;
  }

  static updateXParkLocationsByRatePlan(products = [], xParkLots = []) {
    products?.forEach((product) => {
      product?.ratePlans?.forEach((ratePlan) => {
        var ratePlanName = ratePlan.name;
        if (ratePlanName.indexOf("-") === -1) {
          console.log("Rate plan name did not contain a hyphen - skipping");
          return;
        }
        var locationAid = ratePlanName.split("-")[0].trim();
        var xParkLocation = xParkLots.find((x) => `${x.LotID}` === locationAid);
        if (xParkLocation) {
          const { amount, priceDisplay } = DataUtil.calculatePriceDisplay(
            ratePlan.price
          );
          xParkLocation.priceDisplay = priceDisplay;
          xParkLocation.Amount = amount;
          xParkLocation.HasSubscription = true;
          xParkLocation.ProductCode = product.prdctCd;
        }
      });
    });
  }

  static updateXParkLocations(products = [], xParkLots = []) {
    products.forEach((product) => {
      var productName = product.prdctName;
      if (productName.indexOf("-") === -1) {
        console.log("Rate plan name did not contain a hyphen - skipping");
        return;
      }
      var locationAid = productName.split("-")[0].trim();
      var xParkLocation = xParkLots.find((x) => `${x.LotID}` === locationAid);
      if (xParkLocation) {
        const { amount, priceDisplay } = DataUtil.calculatePriceDisplay(
          product.ratePlans?.[0].price
        );
        xParkLocation.priceDisplay = priceDisplay;
        xParkLocation.Amount = amount;
        xParkLocation.HasSubscription = true;
        xParkLocation.ProductCode = product.prdctCd;
      }
    });
  }

  static isWMATALocation(location) {
    return location?.Name?.indexOf?.("WMATA") === 0;
  }

  static isTMCLocation(location) {
    return location?.Name?.indexOf?.("TMC") === 0;
  }

  static parseXParkLocations(lots = []) {
    return lots.map((lot) => {
      return {
        ...lot,
        LotID: lot.ID,
        Xpark: true,
        HasSubscription: false, // will be updated later on
        resultType: "S",
      };
    });
  }

  static parseSubscriptionLocations(lots = []) {
    return lots.map((item) => {
      const place = item?.place;
      const ratePlan = item?.products?.[0]?.ratePlans?.[0];
      return {
        Name: place.name,
        Longitude: place.location.longitude,
        Latitude: place.location.latitude,
        Address1: place.address.line1,
        Address2: place.address.line2,
        City: place.address.city,
        State: place.address.state,
        Zip: place.address.zip,
        ID: place.code,
        LocNo: place.code,
        HasSubscription: true, // redundant but matches xPark location data structure
        Amount: ratePlan?.price,
        ImageUrl: place?.photos?.[0]?.contentUrl,
        resultType: "S",
      };
    });
  }

  static getCurDateRounded(dateProp) {
    if (dateProp) {
      var a = new Date(dateProp).toString();
      var b = new Date();
      b.setHours(0, 0, 0);
      b = b.toString();
      var newDateTime, endDateTime, currentTime, newDate;
      if (a === b) {
        newDateTime = this.roundTime(new moment());
        endDateTime = moment(newDateTime).add(120, "m");
        endDateTime = this.roundTime(endDateTime);
        newDate = new Date();
        return [newDate, newDateTime, endDateTime];
      } else {
        newDateTime = this.roundTime(new moment(dateProp));
        newDateTime.setHours(8);
        endDateTime = moment(newDateTime).add(120, "m");
        endDateTime = this.roundTime(endDateTime);
        newDate = new Date(dateProp);
        return [newDate, newDateTime, endDateTime];
      }
    } else {
      currentTime = this.roundTime(new moment());
      endDateTime = moment(currentTime).add(120, "m");
      endDateTime = this.roundTime(endDateTime);
      return [new Date(), currentTime, endDateTime];
    }
  }

  static getCurDate(dateProp) {
    var currentTime, endDate;
    if (dateProp) {
      currentTime = new moment(dateProp).toDate();
      endDate = moment(currentTime).add(120, "m").toDate();
    } else {
      currentTime = new moment();
      endDate = moment(currentTime).add(120, "m");
    }

    return [new Date(), currentTime, endDate];
  }

  static roundTime(time) {
    return moment(
      Math.ceil(time / moment.duration(30, "minutes")) *
        moment.duration(30, "minutes")
    ).toDate();
  }

  static zeroOutTimestamp = (date = moment()) => {
    const newDate = moment(date).utcOffset(0);
    newDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return newDate;
  };

  static roundDateToMonth = (date = moment(), format) => {
    let oldDate = moment(date);
    let oldDateDay = parseInt(oldDate.format("D"), 10);
    let oldDateMonth = parseInt(moment(date).format("M"), 10);
    let isFebruary = oldDateMonth === 2;
    let newDate = null;

    if (oldDateDay < 16) {
      newDate = moment(date).set("date", 1).startOf("day");
    } else if (oldDateDay > 16 && isFebruary) {
      newDate = moment(date).set("date", 14).startOf("day");
    } else {
      newDate = moment(date).set("date", 16).startOf("day");
    }

    if (format) {
      return newDate.format(format);
    }
    return new Date(newDate.toISOString());
  };

  static calculateMinuteDifferenceFromCurrentTime(start) {
    if (!start) {
      return null;
    }
    const momentDate1 = moment();
    const momentDate2 = moment(start);

    const differenceInMinutes = momentDate1.diff(momentDate2, "minutes");

    return differenceInMinutes;
  }

  static updateQueryParamsWithTimestamps({
    start,
    end,
    clearPrevious = false,
    extra = {},
    flow = null,
  }) {
    try {
      const queryParams = {
        ...extra,
        ...(clearPrevious
          ? {}
          : qs.parse(window.location.search?.split?.("?")?.[1]) || {}),
      };
      if (start) {
        const differenceInMinutes =
          SearchUtil.calculateMinuteDifferenceFromCurrentTime(start);
        if (differenceInMinutes === 0 && flow !== "airport") {
          const currentDate = moment();
          start = currentDate.toISOString();
          if (end) {
            const minDifference = Math.ceil(
              Math.abs(moment(currentDate).diff(moment(end), "minutes", true))
            );
            end = currentDate.add(minDifference, "minutes").toISOString();
          }
        }
        queryParams.start = start;
      } else {
        delete queryParams.start;
      }
      if (end) {
        queryParams.end = end;
      } else {
        delete queryParams.end;
      }
      return `?${qs.stringify(queryParams)}`;
    } catch (error) {
      console.warn(error);
      return "";
    }
  }

  static showTimePicker() {
    // Please refer to the Confluence documentation when editing this function.
    let showTimePicker = true;

    const queryParam =
      reduxStore.store.getState().router?.location?.query || {};
    const languageData =
      reduxStore.store.getState().language?.languageData || {};

    let locationAllowsTimePicker = languageData?.SHOWTIMEPICKER;

    // If the URL contains any of the following widgetTypes do NOT show the time picker.
    const widgetTypes = ["fap", "fep", "mup", "pass", "evt"];

    for (const widgetType of widgetTypes) {
      if (queryParam?.wt?.includes(widgetType)) {
        showTimePicker = false;
      }
    }

    // If the URL contains a rid or the language label is set to false do NOT show the time picker.
    if (queryParam?.rid || locationAllowsTimePicker === "false")
      showTimePicker = false;

    return showTimePicker;
  }
}
