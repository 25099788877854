import { useGoogleServices } from "../../hooks/useGoogleServices";
import PageLoader from "../Common/PageLoader";

export default function withGoogleServices(Component) {
  return function BaseComponent(props) {
    const { isLoaded, loadError } = useGoogleServices();
    if (!isLoaded) {
      return <PageLoader />;
    } else if (loadError) {
      return null;
    } else {
      return <Component {...props} />;
    }
  };
}
