import { SET_BRANDING, RESET_BRANDING } from "../actions/branding.actions";
import logo from "../../assets/image/laz-logo.svg";

export const INITIAL_STATE = {
  primaryColor: "#007dba",
  secondaryColor: "#444444",
  logo: logo,
};

const brandingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BRANDING:
      return {
        ...state,
        primaryColor: action.payload.primaryColor || INITIAL_STATE.primaryColor,
        secondaryColor:
          action.payload.secondaryColor || INITIAL_STATE.secondaryColor,
        logo: action.payload.logo || INITIAL_STATE.logo,
      };
    case RESET_BRANDING:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default brandingReducer;
