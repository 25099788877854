export const SET_PAYG_LOCATION_BEGIN = "SET_PAYG_LOCATION_BEGIN";
export const SET_PAYG_LOCATION_SUCCESS = "SET_PAYG_LOCATION_SUCCESS";
export const SET_PAYG_LOCATION_ERROR = "SET_PAYG_LOCATION_ERROR";

export const SAVE_PAYG_ACTIVE_SESSIONS = "SAVE_PAYG_ACTIVE_SESSIONS";

export const SAVE_PAYG_BRANDING = "SAVE_PAYG_BRANDING";

export const SET_PAYG_FORM_DATA = "SET_PAYG_FORM_DATA";

export const GET_PAYG_USER_BEGIN = "GET_PAYG_USER_BEGIN";
export const GET_PAYG_USER_SUCCESS = "GET_PAYG_USER_SUCCESS";
export const GET_PAYG_USER_ERROR = "GET_PAYG_USER_ERROR";

export const SET_PAYG_OPT_IN_STATUS_BEGIN = "SET_PAYG_OPT_IN_STATUS_BEGIN";
export const SET_PAYG_OPT_IN_STATUS_SUCCESS = "SET_PAYG_OPT_IN_STATUS_SUCCESS";
export const SET_PAYG_OPT_IN_STATUS_ERROR = "SET_PAYG_OPT_IN_STATUS_ERROR";

export const SET_PAYG_PAYMENT_METHOD_BEGIN = "SET_PAYG_PAYMENT_METHOD_BEGIN";
export const SET_PAYG_PAYMENT_METHOD_SUCCESS =
  "SET_PAYG_PAYMENT_METHOD_SUCCESS";
export const SET_PAYG_PAYMENT_METHOD_ERROR = "SET_PAYG_PAYMENT_METHOD_ERROR";

export const VERIFY_PAYG_CREDENTIAL_BEGIN = "VERIFY_PAYG_CREDENTIAL_BEGIN";
export const VERIFY_PAYG_CREDENTIAL_SUCCESS = "VERIFY_PAYG_CREDENTIAL_SUCCESS";
export const VERIFY_PAYG_CREDENTIAL_ERROR = "VERIFY_PAYG_CREDENTIAL_ERROR";

export const SET_AUTHENTICATE_SHEET_OPEN = "SET_AUTHENTICATE_SHEET_OPEN";

export const START_PAYG_SESSION_BEGIN = "START_PAYG_SESSION_BEGIN";
export const START_PAYG_SESSION_SUCCESS = "START_PAYG_SESSION_SUCCESS";
export const START_PAYG_SESSION_ERROR = "START_PAYG_SESSION_ERROR";

export const END_PAYG_SESSION_BEGIN = "END_PAYG_SESSION_BEGIN";
export const END_PAYG_SESSION_SUCCESS = "END_PAYG_SESSION_SUCCESS";
export const END_PAYG_SESSION_ERROR = "END_PAYG_SESSION_ERROR";

export const CONTINUE_PAYG_SESSION_BEGIN = "CONTINUE_PAYG_SESSION_BEGIN";
export const CONTINUE_PAYG_SESSION_SUCCESS = "CONTINUE_PAYG_SESSION_SUCCESS";
export const CONTINUE_PAYG_SESSION_ERROR = "CONTINUE_PAYG_SESSION_ERROR";

export const GET_PAYG_SESSION_BEGIN = "GET_PAYG_SESSION_BEGIN";
export const GET_PAYG_SESSION_SUCCESS = "GET_PAYG_SESSION_SUCCESS";
export const GET_PAYG_SESSION_ERROR = "GET_PAYG_SESSION_ERROR";

export const GET_PAYG_SESSIONS_BEGIN = "GET_PAYG_SESSIONS_BEGIN";
export const GET_PAYG_SESSIONS_SUCCESS = "GET_PAYG_SESSIONS_SUCCESS";
export const GET_PAYG_SESSIONS_ERROR = "GET_PAYG_SESSIONS_ERROR";

export const SAVE_PAYG_VEHICLE_BEGIN = "SAVE_PAYG_VEHICLE_BEGIN";
export const SAVE_PAYG_VEHICLE_SUCCESS = "SAVE_PAYG_VEHICLE_SUCCESS";
export const SAVE_PAYG_VEHICLE_ERROR = "SAVE_PAYG_VEHICLE_ERROR";

export const UPDATE_PAYG_VEHICLE_BEGIN = "UPDATE_PAYG_VEHICLE_BEGIN";
export const UPDATE_PAYG_VEHICLE_SUCCESS = "UPDATE_PAYG_VEHICLE_SUCCESS";
export const UPDATE_PAYG_VEHICLE_ERROR = "UPDATE_PAYG_VEHICLE_ERROR";

export const VERIFY_AUTH_CODE_BEGIN = "VERIFY_AUTH_CODE_BEGIN";
export const VERIFY_AUTH_CODE_SUCCESS = "VERIFY_AUTH_CODE_SUCCESS";
export const VERIFY_AUTH_CODE_ERROR = "VERIFY_AUTH_CODE_ERROR";
