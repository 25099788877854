export const HANDLE_MAP_HOVER = "HANDLE_MAP_HOVER";
export const SET_MAP_STATE = "SET_MAP_STATE";
export const SET_SEARCH_LOADING = "SET_SEARCH_LOADING";
export const SET_SEARCH_LOTS = "SET_SEARCH_LOTS";
export const SET_SEARCH_ALL_LOTS = "SET_SEARCH_ALL_LOTS";
export const SET_SEARCH_FILTERED_LOTS = "SET_SEARCH_FILTERED_LOTS";
export const SET_SEARCH_PRODUCTS = "SET_SEARCH_PRODUCTS";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const SET_SEARCH_NO_RESULTS = "SET_SEARCH_NO_RESULTS";
export const RESET_MAP_STATE = "RESET_MAP_STATE";
export const SET_DURATIONS = "SET_DURATIONS";
export const SET_LOCATION_DURATIONS = "SET_LOCATION_DURATIONS";

export function handleMapHover(hoverLocationId) {
  return {
    type: HANDLE_MAP_HOVER,
    payload: hoverLocationId,
  };
}

export function setMapState(payload, meta = {}) {
  return {
    type: SET_MAP_STATE,
    payload,
    meta,
  };
}

export function setSearchLoading(bool) {
  return {
    type: SET_SEARCH_LOADING,
    payload: !!bool,
  };
}

export function setSearchProducts(payload = []) {
  return {
    type: SET_SEARCH_PRODUCTS,
    payload,
  };
}

export function setSearchLots(payload = []) {
  return {
    type: SET_SEARCH_LOTS,
    payload,
  };
}

export function setSearchAllLots(payload = []) {
  return {
    type: SET_SEARCH_ALL_LOTS,
    payload,
  };
}

export function setSearchFilteredLots(payload = [], oneTime, locationFilter) {
  return {
    type: SET_SEARCH_FILTERED_LOTS,
    payload,
    meta: {
      oneTime,
      locationFilter,
    },
  };
}

export function setSearchFilters(payload = {}, oneTime, locationFilter) {
  return {
    type: SET_SEARCH_FILTERS,
    payload,
    meta: {
      oneTime,
      locationFilter,
    },
  };
}

export function setSearchNoResults(bool = false) {
  return {
    type: SET_SEARCH_NO_RESULTS,
    payload: bool,
  };
}

export function resetMapState() {
  global.debugMap && console.log("MAP DEBUG", "Map state reset");
  return {
    type: RESET_MAP_STATE,
  };
}

export function setDurations(payload = []) {
  return {
    type: SET_DURATIONS,
    payload,
  };
}

export function setLocationDurations(payload = []) {
  return {
    type: SET_LOCATION_DURATIONS,
    payload,
  };
}
