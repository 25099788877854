import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import createTransform from "redux-persist/es/createTransform";
import userReducer, {
  INITIAL_STATE as userInitialState,
} from "./reducers/user.reducer";
import searchReducer, {
  INITIAL_STATE as searchInitialState,
} from "./reducers/search.reducer";
import cartReducer, {
  INITIAL_STATE as cartInitialState,
} from "./reducers/cart.reducer";
import airportReducer, {
  INITIAL_STATE as airportInitialState,
} from "./reducers/airport.reducer";
import languageReducer, {
  INITIAL_STATE as languageInitialState,
} from "./reducers/language.reducer";
import brandingReducer, {
  INITIAL_STATE as brandingInitialState,
} from "./reducers/branding.reducer";
import paygReducer, {
  INITIAL_STATE as paygInitialState,
} from "./reducers/payg.reducer";
import uiReducer, {
  INITIAL_STATE as uiReducerInitialState,
} from "./reducers/ui.reducer";
import { INITIAL_STATE as routerInitialState } from "./reducers/location.reducer";
import pjson from "../../package.json";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import { combineReducers } from "redux";
import { createReduxHistoryContext } from "../store/operations/location.operations";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "@redux-devtools/extension";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: true,
  });

const userDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return {
      ...userInitialState,
      isLoginViaEmu: outboundState.isLoginViaEmu,
      isLoginViaNu: outboundState.isLoginViaNu,
      purchaseType: outboundState.purchaseType,
      userAccount: outboundState.userAccount,
    };
  },
  { whitelist: ["user"] }
);

const paygDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return {
      ...outboundState,
      formData: {
        ...(outboundState?.formData || {}),
        validationContext: paygInitialState.formData.validationContext,
      },
      authenticateSheetOpen: false,
    };
  },
  { whitelist: ["payg"] }
);

const languageDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return languageInitialState;
  },
  { whitelist: ["language"] }
);

const routerDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return routerInitialState;
  },
  { whitelist: ["router"] }
);

const searchDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return searchInitialState;
  },
  { whitelist: ["search"] }
);

const airportDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return {
      ...outboundState,
      loading: airportInitialState.loading,
    };
  },
  { whitelist: ["airport"] }
);

const brandingDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return brandingInitialState;
  },
  { whitelist: ["branding"] }
);

const cartDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    const cartTimeStamp = outboundState?.timestamp;
    if (cartTimeStamp) {
      let isCartExpired =
        parseInt(cartTimeStamp) + 1800000 < new Date().getTime();
      if (isCartExpired) {
        return cartInitialState;
      }
    }
    return outboundState;
  },
  { whitelist: ["cart"] }
);

const uiDisallow = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState) => {
    return uiReducerInitialState;
  },
  { whitelist: ["ui"] }
);

const persistConfig = {
  key: "root",
  transforms: [
    userDisallow,
    cartDisallow,
    languageDisallow,
    brandingDisallow,
    paygDisallow,
    routerDisallow,
    searchDisallow,
    airportDisallow,
    uiDisallow,
  ],
  encoding: "utf8",
  storage,
  version: pjson?.version,
  stateReconciler: hardSet,
  migrate: handleStateMigration,
  debug: process.env.NODE_ENV === "development",
};

const reducers = combineReducers({
  router: routerReducer,
  user: userReducer,
  language: languageReducer,
  search: searchReducer,
  cart: cartReducer,
  airport: airportReducer,
  branding: brandingReducer,
  payg: paygReducer,
  ui: uiReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT" && action.meta.clearAllCopy) {
    storage.removeItem("persist:root");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

function handleStateMigration(state) {
  if (state && state?._persist?.version) {
    if (state?._persist?.version !== pjson?.version) {
      console.log(
        `Migrating from v${state?._persist?.version} to v${pjson?.version}`
      );
      return Promise.resolve(undefined);
    }
  }
  return Promise.resolve(state);
}

function configureStore() {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const enhancer = composeWithDevTools(
    applyMiddleware(thunk, routerMiddleware)
  );
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  const history = createReduxHistory(store);
  return { store, persistor, history };
}

const store = configureStore();
export default store;
