import { LOCATION_CHANGE } from "../types/location.types";
import { fetchWindowLocationInformation } from "../../components/Utils/LocationUtil";

const { isParkingStartingNow, searchTerm, query, enteredViaWidget } =
  fetchWindowLocationInformation(window.location);

export const INITIAL_STATE = {
  location: {
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    isParkingStartingNow,
    startString: query.start ? new Date(query.start).toLocaleString() : "",
    endString: query.end ? new Date(query.end).toLocaleString() : "",
    searchTerm,
    query,
    enteredViaWidget,
  },
  action: null,
  basename: undefined,
  previousLocations: [],
};

export const createRouterReducer = ({
  savePreviousLocations = 0,
  basename,
}) => {
  const initialState = {
    ...INITIAL_STATE,
    basename,
  };

  const numLocationToTrack = isNaN(savePreviousLocations)
    ? 0
    : savePreviousLocations;
  if (numLocationToTrack) initialState.previousLocations = [];

  return (state = initialState, { type, payload } = {}) => {
    if (type === LOCATION_CHANGE) {
      const { location, action } = payload || {};
      const previousLocations = numLocationToTrack
        ? [
            { location, action },
            ...state.previousLocations.slice(0, numLocationToTrack),
          ]
        : undefined;
      return { ...state, location, action, previousLocations };
    }
    return state;
  };
};
