import TagManager from "react-gtm-module";

const InitGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID}`,
  };

  TagManager.initialize(tagManagerArgs);

  // Keep console log for future debugging purposes.
  // console.log("GTM INITIALIZED");
  // console.log("GoogleTagManager.js - TRACKING_ID:", tagManagerArgs);
};

const formatSalesChannelKey = (salesChannelKey) => {
  let key = salesChannelKey;

  // Send over the sales channel value only when we pass 't2p' or 'pnqr'.
  if (!key || key === "" || (key !== "t2p" && key !== "pnqr")) {
    key = null;
  } else {
    key = salesChannelKey.toLowerCase();
  }

  return key;
};

const indexCartItems = (selectedRates) => {
  let items = [];
  if (selectedRates) {
    if (selectedRates.length) {
      selectedRates.forEach((rate) => {
        let item = {
          item_id: rate?.rate?.RateId || null,
          item_name: rate?.rate?.RateName || null,
          location_id: rate?.rate?.LocationId || null,
          price: rate?.rate?.Amount || null,
        };
        items.push(item);
      });
    } else {
      let item = {
        item_id: selectedRates?.rate?.RateId || null,
        item_name: selectedRates?.rate?.RateName || null,
        location_id: selectedRates?.rate?.LocationId || null,
        price: selectedRates?.rate?.Amount || null,
      };
      items.push(item);
    }
  } else {
    return;
  }

  return items;
};

// RECOMMENDED EVENTS: Below are GA4's recommended events. These will integrate best with the GA4 and GTM platforms.

const UpdateSearchEvent = ({ searchTerm, searchFlow }) => {
  let LocationData = {
    dataLayer: {
      event: "search",
      search_term: searchTerm || null,
      transaction_type: searchFlow || null,
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateViewItemEvent = ({ isOneTime, locId, locName, scKey }) => {
  let LocationData = {
    dataLayer: {
      event: "view_item",
      item_list_id: locId?.toString() || null,
      item_list_name: locName || null,
      sales_channel: formatSalesChannelKey(scKey),
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: [
        {
          item_id: locId?.toString() || null,
          item_name: locName || null,
          sales_channel: formatSalesChannelKey(scKey),
        },
      ],
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateSelectItemEvent = ({
  isOneTime,
  selectedRate,
  scKey,
  locId,
  locName,
}) => {
  let LocationData = {
    dataLayer: {
      event: "select_item",
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: [
        {
          item_id: selectedRate?.rate?.RateId || null,
          item_name: selectedRate?.rate?.RateName || null,
          location_id: selectedRate?.rate?.LocationId || null,
          price: selectedRate?.rate?.Amount || null,
        },
      ],
    },
  };
  TagManager.dataLayer(LocationData);
};

const UpdateAddToCartEvent = ({
  isOneTime,
  selectedRate,
  locId,
  locName,
  scKey,
  amount,
}) => {
  let LocationData = {
    dataLayer: {
      event: "add_to_cart",
      currency: "USD",
      value: amount,
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: [
        {
          item_id: selectedRate?.rate?.RateId || null,
          item_name: selectedRate?.rate?.RateName || null,
          location_id: selectedRate?.rate?.LocationId || null,
          price: selectedRate?.rate?.Amount || null,
        },
      ],
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateRemoveFromCartEvent = ({
  isOneTime,
  selectedRate,
  locId,
  locName,
  scKey,
  amount,
}) => {
  let LocationData = {
    dataLayer: {
      event: "remove_from_cart",
      currency: "USD",
      value: amount,
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: [
        {
          item_id: selectedRate?.rate?.RateId || null,
          item_name: selectedRate?.rate?.RateName || null,
          location_id: selectedRate?.rate?.LocationId || null,
          price: selectedRate?.rate?.Amount || null,
        },
      ],
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateViewCartEvent = ({
  isOneTime,
  cartTotal,
  selectedRates,
  locId,
  locName,
  scKey,
}) => {
  let LocationData = {
    dataLayer: {
      event: "view_cart",
      currency: "USD",
      value: cartTotal,
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: indexCartItems(selectedRates),
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateBeginCheckoutEvent = ({
  isOneTime,
  checkoutTotal,
  selectedRates,
  locId,
  locName,
  scKey,
}) => {
  let LocationData = {
    dataLayer: {
      event: "begin_checkout",
      currency: "USD",
      value: checkoutTotal,
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: indexCartItems(selectedRates),
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateAddShippingInfoEvent = ({
  isOneTime,
  checkoutTotal,
  selectedRates,
  locId,
  locName,
  scKey,
}) => {
  let LocationData = {
    dataLayer: {
      event: "add_shipping_info",
      currency: "USD",
      value: checkoutTotal,
      shipping_tier: "digital",
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: indexCartItems(selectedRates),
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateAddPaymentInfoEvent = ({
  isOneTime,
  checkoutTotal,
  selectedRates,
  locId,
  locName,
  scKey,
  paymentType,
}) => {
  let LocationData = {
    dataLayer: {
      event: "add_payment_info",
      currency: "USD",
      value: checkoutTotal,
      payment_type: paymentType,
      sales_channel: formatSalesChannelKey(scKey),
      location_id: locId?.toString() || null,
      location_name: locName || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      items: indexCartItems(selectedRates),
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdatePurchaseEvent = ({
  isOneTime,
  confirmationNumber,
  selectedRates,
  locId,
  locName,
  scKey,
  finalAmountCharged,
}) => {
  let LocationData = {
    dataLayer: {
      event: "purchase",
      sales_channel: formatSalesChannelKey(scKey),
      location_name: locName || null,
      location_id: locId?.toString() || null,
      transaction_type: isOneTime ? "One Time" : "Subscription",
      ecommerce: {
        transaction_id: confirmationNumber || null,
        currency: "USD",
        //Value is the final total charge at checkout
        value: finalAmountCharged,
        transaction_type: isOneTime ? "One Time" : "Subscription",
        items: indexCartItems(selectedRates),
      },
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateLoginEvent = ({}) => {
  let LocationData = {
    dataLayer: {
      event: "login",
    },
  };

  TagManager.dataLayer(LocationData);
};

const UpdateSignUpEvent = ({}) => {
  let LocationData = {
    dataLayer: {
      event: "sign_up",
    },
  };

  TagManager.dataLayer(LocationData);
};

// CUSTOM EVENTS: Below are Custom Events that will help with data collection specific to LAZ.

const UpdateViewLocationEvent = ({ isOneTime, locId, locName, scKey }) => {
  let LocationData = {
    dataLayer: {
      event: "view_location",
      location_id: locId?.toString() || null,
      location_name: locName || null,
      sales_channel: formatSalesChannelKey(scKey),
      transaction_type: isOneTime ? "One Time" : "Subscription",
    },
  };

  TagManager.dataLayer(LocationData);
};

export default InitGoogleTagManager;
export {
  InitGoogleTagManager,
  UpdateSearchEvent,
  UpdatePurchaseEvent,
  UpdateViewLocationEvent,
  UpdateAddToCartEvent,
  UpdateViewCartEvent,
  UpdateSelectItemEvent,
  UpdateAddShippingInfoEvent,
  UpdateAddPaymentInfoEvent,
  UpdateBeginCheckoutEvent,
  UpdateRemoveFromCartEvent,
  UpdateViewItemEvent,
};
