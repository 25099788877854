import React from "react";

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const Loader = ({ className, style }) => (
  <em
    className={`fas fa-circle-notch fa-spin fa-lg ${className || ""}`}
    style={style}
  />
);

export default Loader;
