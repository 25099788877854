export const SET_DISABLE_START_DATE_AND_TIME =
  "SET_DISABLE_START_DATE_AND_TIME";
export const SET_SEARCH_WIDGET_VISIBILITY = "SET_SEARCH_WIDGET_VISIBILITY";
export const SET_IS_FULL_SEARCH_WIDGET = "SET_IS_FULL_SEARCH_WIDGET";
export const SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE =
  "SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE";
export const TOGGLE_TOAST_VISIBILITY = "TOGGLE_TOAST_VISIBILITY";
export const SET_TOAST = "SET_TOAST";

export function toggleToastVisibility() {
  return {
    type: TOGGLE_TOAST_VISIBILITY,
  };
}

export function setSearchWidgetVisible(visible = false) {
  return {
    type: SET_SEARCH_WIDGET_VISIBILITY,
    payload: visible,
  };
}

export function hideSearchWidget() {
  return {
    type: SET_SEARCH_WIDGET_VISIBILITY,
    payload: false,
  };
}

export function setIsFullSearchWidget(isFullSearch = false) {
  return {
    type: SET_IS_FULL_SEARCH_WIDGET,
    payload: isFullSearch,
  };
}

export function setSearchWidgetBackgroundCloseEnable(closeEnabled = false) {
  return {
    type: SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE,
    payload: closeEnabled,
  };
}

export function setDisableStartDateAndTime(disableStartDateAndTime = false) {
  return {
    type: SET_DISABLE_START_DATE_AND_TIME,
    payload: disableStartDateAndTime,
  };
}

export function setToast({ title, message, type, code }) {
  return {
    type: SET_TOAST,
    payload: { title, message, type, code },
  };
}
