import RestApi from "./RestApi";

const MAX_RETRY_COUNT = 3;

export default class BuyNowService {
  constructor() {
    this.getLocationDataBindRetryCount = 0;
  }

  getLocationDataBind = async (id, scKey) => {
    try {
      const url = "/api/v1/ParkingLocation/GetLocationDataBind";
      const data = {
        ClientId: "",
        AccessCode: "",
        ArrayeDataLocationId: [id],
        WidgetKey: process.env.REACT_APP_GRS_CORE_WIDGET_KEY,
        SalesChannelKey: scKey,
      };

      const response = await RestApi.post(
        process.env.REACT_APP_GRS_V2_URL + url,
        data
      );

      const location = JSON.parse(response?.data?.d || "[]")?.[0];

      if (location) {
        this.getLocationDataBindRetryCount = 0;
        return response;
      } else {
        if (this.getLocationDataBindRetryCount < MAX_RETRY_COUNT) {
          this.getLocationDataBindRetryCount++;
          return this.getLocationDataBind(id, scKey);
        } else {
          this.getLocationDataBindRetryCount = 0;
          throw new Error("No location found");
        }
      }
    } catch (error) {
      this.getLocationDataBindRetryCount = 0;
      throw error;
    }
  };

  getMultipleRatesSimple = (urlParams) => {
    const url = `/api/v1/Rate/GetMultipleRatesJSONPSimple?Start=${encodeURIComponent(
      urlParams.Start
    )}&End=${urlParams.End}&LocationIdCSV=${encodeURIComponent(
      urlParams.LocationIdCSV
    )}`;
    return RestApi.get(process.env.REACT_APP_GRS_V2_URL + url);
  };

  getParkingTimeIncrements = (id) => {
    const url = `/api/v1/ParkingLocation/GetParkingTimeIncrementsJSON?eDataLocationId=${id}`;
    //const url = "/GetParkingTimeIncrementsJSON?callback=jQuery19109271115775928616_1613442020285&eDataLocationId=74455&_=1613442020286"
    return RestApi.get(process.env.REACT_APP_GRS_V2_URL + url);
  };

  getMultipleRates = (criteria, getResultsFromCache, cancelToken) => {
    const url = "/api/v1/Rate/GetMultipleRates";
    const data = {
      Criteria: criteria,
      loadFromSearchCache: getResultsFromCache ?? false,
    };
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data, {
      cancelToken: cancelToken?.token,
      validateStatus: (status) => {
        return true; // I'm always returning true, you may want to do it depending on the status received
      },
    });
  };

  getParkingFeeItems = (criteria) => {
    const url = "/api/v1/ParkingLocation/GetParkingFeeItems";
    //const data = { Criteria: criteria };
    const data = criteria;
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data, {
      validateStatus: (status) => {
        return true; // I'm always returning true, you may want to do it depending on the status received
      },
    });
  };

  searchEventsMultipleRates = (criteria) => {
    const url = "/api/v1/Rate/SearchEventMultipleRate";
    const data = { Criteria: criteria };
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data, {
      validateStatus: (status) => {
        return true; // I'm always returning true, you may want to do it depending on the status received
      },
    });
  };

  getEventMultipleRates = (criteria) => {
    const url = "/api/v1/Rate/GetEventMultipleRate";
    const data = { Criteria: criteria };
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data, {
      validateStatus: (status) => {
        return true; // I'm always returning true, you may want to do it depending on the status received
      },
    });
  };

  getRate = (data) => {
    const url = "/api/v1/Rate/GetRate";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  pay = (data) => {
    const url = "/api/v1/Order/CreateCartLoginReservationJSONPOST";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  payGuestMode = (data) => {
    const url = "/api/v1/Order/CreateCartReservationJSONPOST";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  modify = (data) => {
    const url = "/api/v1/Order/GuestAmendCartReservationJSONPOST"; //AmendCartReservationJSONPOST
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  extend = (data) => {
    const url = "/api/v1/Reservations/ExtendReservation";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  cancelReservation = (data) => {
    const url = "/api/v1/Reservations/CancelReservationByAccount";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  getLanguage = (lang, locationId) => {
    const url = "/api/v1/Language/GetLanguage";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, {
      eDataLocationId: locationId,
      ISOLanguageCode: lang,
    });
  };

  getReservationConfirmation = (data) => {
    const url = "/api/v1/Email/GetReservationConfirmation";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };

  getCustomFieldLabel = async (locationId, data) => {
    const url = "/api/v1/ParkingLocation/GetLocationFields";
    return RestApi.post(process.env.REACT_APP_GRS_V2_URL + url, data);
  };
}
