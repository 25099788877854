export const SET_BRANDING = "SET_BRANDING";
export const RESET_BRANDING = "RESET_BRANDING";

export function setBranding(primaryColor, secondaryColor, logo) {
  return {
    type: SET_BRANDING,
    payload: { primaryColor, secondaryColor, logo },
  };
}

export function resetBranding() {
  return {
    type: RESET_BRANDING,
  };
}
