import { useJsApiLoader } from "@react-google-maps/api";

const LIBRARIES = ["geometry", "drawing", "places"];

export const useGoogleServices = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
    id: "google-map-script",
    libraries: LIBRARIES,
  });
  return {
    isLoaded,
    loadError,
  };
};
